@import "variables";

.footer {
    height: 260px;

    margin-top: 130px;
    margin-bottom: 40px;
}

.footer__content {
    width: 100%;
    height: 100%;

    border-top: 1px solid #d2e4ed;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__logo {
    flex-grow: 1;

    display: flex;
    align-items: center;

    img {
        width: 108px;
        height: 110px;
    }
}

.footer__copyright {
    font-size: 13px;
    line-height: 28px;
    color: #7c8087;
}
